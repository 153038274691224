<template>
  <div style="background-color: #ffffff">
    <!-- form starts -->
    <vue-element-loading
      :active="isSubmitted"
      text="MYLE"
      :text-style="loadStyle"
      :is-full-screen="true"
      class="h-full background-overlay"
      spinner="bar-fade-scale"
      color="#ffffff"
    />

    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :pk="checkout.publishKey"
      :sessionId="checkout.clientReferenceId"
    />

    <!-- conifrm model -->
    <nu-modal ref="confirmmodal" title="">
      <confirmationPopup :showsuccess="showsuccess" @closemodal="closeModal" />
    </nu-modal>
    <!-- confrim model -->

    <nu-modal ref="modal" title="">
      <form>
        <div class="container-fluid">
          <div class="row">
            <!-- Ticket Cart -->
            <div class="col-md-12">
              <div class="text-center">
                <h4
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #000000;
                  "
                >
                  Purchase
                </h4>
              </div>
            </div>

            <div class="col-md-6 mt-3">
              <span
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                Full Name
              </span>
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Full Name"
                required
                v-model="fullname"
              />
            </div>
            <div class="col-md-6 mt-3">
              <span
                class="w-auto d-inline-block labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
              >
                Email
              </span>
              <input
                type="email"
                class="form-control mb-3"
                placeholder="Email"
                v-model="email"
                required
              />
            </div>

            <div class="col-md-6 mt-3">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Phone
              </label>
              <input
                type="text"
                class="form-control"
                placeholder="Enter phone number"
                @keypress="isNumber()"
                v-model="userContactPhone"
              />
              <span
                v-bind:class="{ 'op-0': !(istouched && !invaliduphone) }"
                class="mb-3"
                style="color: red; font-size: 12px"
                >*please fill valid phone number</span
              >
            </div>

            <div class="col-md-6 mt-3">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Business Name</label
              >
              <input
                type="text"
                class="form-control mb-3"
                placeholder="Business Name"
                required
                v-model="nameOfBusiness"
              />
            </div>

            <div class="col-md-6 mt-5">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Will you attend the Awards Gala?
              </label>
              <select class="form-control" v-model="attendingGala">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in ['Yes', 'No']"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <div class="col-md-6 mt-4" v-if="!isDonate">
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Do you plan to participate in the Vendor Connect matchmaking
                event?
              </label>
              <select class="form-control" v-model="participateInVendor">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in ['Yes', 'No']"
                  :value="count"
                >
                  {{ count }}
                </option>
              </select>
            </div>

            <!-- <div class="col-md-6 mt-4">
                <span
                  class="w-auto d-inline-block labels"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                >
                  Where did you go to school?
                </span>
                <input
                  type="email"
                  placeholder="Enter your school name"
                  class="form-control mb-3"
                  v-model="userSchool"
                />
              </div> -->

            <hr v-if="!isDonate" class="w-100 p-2" />
            <!-- Ticket Cart -->

            <!-- Ticket Options -->
            <div class="col-md-12" v-if="!isDonate && !isSponsor">
              <div class="text-center">
                <h4
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #000000;
                  "
                >
                  Ticket Options
                </h4>
              </div>
            </div>

            <div class="col-md-4" v-if="!isDonate && !isSponsor">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Ticket Type</label
              >

              <!-- <select
                    @change="ticketChange()"
                    class="form-control"
                    v-model="ttypelist"
                  >
                    <option
                      class="form-control"
                      v-bind:key="count + 'b'"
                      v-for="count in TicketsResult"
                      :value="count"
                    >
                      {{ count.ticketName }}
                    </option>
                  </select> -->

              <select
                @change="dayChange('daylist')"
                class="form-control"
                v-model="daylist"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in fetchRouteTickets(completeList, true, false)"
                  :value="count"
                >
                  {{ count.ticketName }}
                </option>
              </select>
            </div>

            <div class="col-md-4 mb-3" v-if="!isDonate && !isSponsor">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Number of Ticket(s)</label
              >
              <!-- <select
                    class="form-control"
                    v-model="tickcount"
                    @change="countChange()"
                  >
                    <option
                      class="form-control"
                      v-bind:key="count + 'b'"
                      v-for="count in tickcountlist"
                      :value="count"
                    >
                      {{ count }}
                    </option>
                  </select> -->

              <select class="form-control" v-model="tickcount">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist"
                  :value="count"
                >
                  {{ count }}
                </option>

                <option
                  disabled
                  v-if="
                    tickcountlist.length == 0 &&
                    initvalue[daylist.ticketCode] != 0
                  "
                >
                  No more tickets
                </option>

                <option disabled v-if="initvalue[daylist.ticketCode] == 0">
                  Sold Out
                </option>
              </select>
            </div>

            <div
              class="col-md-4 mt-4 text-center"
              v-if="!isDonate && !isSponsor"
            >
              <p
                v-if="
                  TicketsResult[selected] &&
                  TicketsResult[selected].ticketType != 'FREE'
                "
              >
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Per Person:</b
                >

                <!-- ${{ ttypelist ? ttypelist.price.toFixed(2) : "" }} -->

                ${{
                  !redeemData
                    ? ttypelist && ttypelist.price
                      ? ttypelist.price.toFixed(2)
                      : "0.00"
                    : redeemData.price.toFixed(2)
                }}
              </p>
              <p v-if="redeemData && redeemData.price">
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Per Donation:</b
                >
                ${{ redeemData.price.toFixed(2) }}
              </p>
              <p v-if="!redeemData">
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Total Amount:</b
                >

                ${{
                  (
                    (ttypelist && ttypelist.price ? ttypelist.price || 0 : 0) *
                    (tickcount || 0)
                  ).toFixed(2)
                }}
              </p>
              <p v-if="redeemData">
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Total Amount:</b
                >
                ${{
                  (
                    (redeemData ? redeemData.price || 0 : 0) * (tickcount || 0)
                  ).toFixed(2)
                }}
              </p>
            </div>

            <div
              class="col-md-3"
              v-if="
                TicketsResult[selected] &&
                isDonate == false &&
                isSponsor == false &&
                TicketsResult[selected].discount
              "
            ></div>

            <div
              class="col-md-6 mt-3 text-center"
              v-if="
                TicketsResult[selected] &&
                isDonate == false &&
                isSponsor == false &&
                TicketsResult[selected].discount
              "
            >
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Do you have a promo code?</label
              >

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />

              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-UNCFGala btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-3 text-center"
              id="redeem"
              v-if="
                TicketsResult[selected] &&
                isDonate == false &&
                isSponsor == false &&
                TicketsResult[selected].discount
              "
            ></div>

            <div
              v-if="!isDonate && !isSponsor"
              class="col-md-12 text-center my-4"
            >
              <input
                type="button"
                @click="addtocart('daylist')"
                class="btn solid-btn-UNCFGala m-auto mr-2"
                :disabled="!daylist || !tickcount"
                value="Add to cart"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              />
            </div>

            <hr v-if="isDonate" class="w-100 p-2" />
            <!-- Ticket Options -->

            <!-- Donation Options -->
            <div v-if="isDonate" class="col-md-12 mb-3">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #000000;
                  "
                >
                  Vendor Registration(s)
                </span>
              </div>
            </div>

            <div v-if="isDonate" class="col-md-4">
              <label
                class="mt-3 labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Registration Type</label
              >
              <select
                @change="dayChange('daylist1')"
                class="form-control"
                v-model="daylist1"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in fetchRouteTickets(completeList, false, true)"
                  :value="count"
                >
                  {{ count.ticketName }}
                </option>
              </select>
            </div>

            <div v-if="isDonate" class="col-md-4 mb-3">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Number of Registration(s)</label
              >
              <select class="form-control" v-model="tickcount1">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist1"
                  :value="count"
                >
                  {{ count }}
                </option>

                <option
                  disabled
                  v-if="
                    tickcountlist1.length == 0 &&
                    initvalue[daylist1.ticketCode] != 0
                  "
                >
                  No more vendor registration
                </option>

                <option disabled v-if="initvalue[daylist1.ticketCode] == 0">
                  Sold Out
                </option>
              </select>
            </div>

            <div v-if="isDonate" class="col-md-4 mt-4 text-center">
              <p>
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                >
                  Per Amount:</b
                >
                ${{
                  ttypelist1 && ttypelist1.price
                    ? ttypelist1.price.toFixed(2)
                    : "0.00"
                }}
              </p>
              <p>
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Total Amount:</b
                >
                ${{
                  (
                    (ttypelist1 && ttypelist1.price
                      ? ttypelist1.price || 0
                      : 0) * (tickcount1 || 0)
                  ).toFixed(2)
                }}
              </p>
            </div>

            <div
              class="col-md-3"
              v-if="
                TicketsResult[selected] &&
                isDonate == true &&
                TicketsResult[selected].discount
              "
            ></div>

            <div
              class="col-md-6 mt-3 text-center"
              v-if="
                TicketsResult[selected] &&
                isDonate == true &&
                TicketsResult[selected].discount
              "
            >
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Do you have a promo code?</label
              >

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />

              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-UNCFGala btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div>

            <div
              class="col-md-3 text-center"
              id="redeem"
              v-if="
                TicketsResult[selected] &&
                isDonate == true &&
                TicketsResult[selected].discount
              "
            ></div>

            <div v-if="isDonate" class="col-md-12 text-center my-4">
              <input
                type="button"
                @click="addtocart('daylist1')"
                class="btn solid-btn-UNCFGala m-auto mr-2"
                :disabled="!daylist1 || !tickcount1"
                value="Add To Cart"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              />
            </div>

            <!-- Donation Options -->
            <div v-if="isSponsor" class="col-md-12 mb-3">
              <div class="text-center">
                <span
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #000000;
                  "
                >
                  Sponsorship Registration(s)
                </span>
              </div>
            </div>

            <div v-if="isSponsor" class="col-md-4">
              <label
                class="mt-3 labels"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Sponsorship Type</label
              >
              <select
                @change="dayChange('daylist1')"
                class="form-control"
                v-model="daylist1"
              >
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in fetchRouteTickets(completeList, false, false)"
                  :value="count"
                >
                  {{ count.ticketName }}
                </option>
              </select>
            </div>

            <div v-if="isSponsor" class="col-md-4 mb-3">
              <label
                class="mt-3"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Number of Sponsorship(s)</label
              >
              <select class="form-control" v-model="tickcount1">
                <option
                  class="form-control"
                  v-bind:key="count + 'b'"
                  v-for="count in tickcountlist1"
                  :value="count"
                >
                  {{ count }}
                </option>

                <option
                  disabled
                  v-if="
                    tickcountlist1.length == 0 &&
                    initvalue[daylist1.ticketCode] != 0
                  "
                >
                  No more sponsorship registration
                </option>

                <option disabled v-if="initvalue[daylist1.ticketCode] == 0">
                  Sold Out
                </option>
              </select>
            </div>

            <div v-if="isSponsor" class="col-md-4 mt-4 text-center">
              <p>
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                >
                  Per Amount:</b
                >
                ${{
                  ttypelist1 && ttypelist1.price
                    ? ttypelist1.price.toFixed(2)
                    : "0.00"
                }}
              </p>
              <p>
                <b
                  class="font-weight-bold"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 500;
                    font-size: 1.2em;
                    color: black;
                  "
                  >Total Amount:</b
                >
                ${{
                  (
                    (ttypelist1 && ttypelist1.price
                      ? ttypelist1.price || 0
                      : 0) * (tickcount1 || 0)
                  ).toFixed(2)
                }}
              </p>
            </div>

            <div
              class="col-md-3"
              v-if="
                TicketsResult[selected] &&
                isSponsor == true &&
                TicketsResult[selected].discount
              "
            ></div>

            <!-- <div
              class="col-md-6 mt-3 text-center"
              v-if="
                TicketsResult[selected] &&
                isSponsor == true &&
                TicketsResult[selected].discount
              "
            >
              <label
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 1.2em;
                  color: black;
                "
                >Do you have a promo code?</label
              >

              <input
                type="text"
                v-model="redeempromo"
                class="form-control"
                placeholder="Promo code"
              />

              <button
                type="button"
                @click="redeem()"
                :disabled="!redeempromo.trim().length"
                class="btn solid-btn-UNCFGala btn-sm my-auto"
                id="reserve"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  padding: 7px;
                  margin-top: 11px !important;
                "
              >
                Redeem Code
              </button>
            </div> -->

            <div
              class="col-md-3 text-center"
              id="redeem"
              v-if="
                TicketsResult[selected] &&
                isSponsor == true &&
                TicketsResult[selected].discount
              "
            ></div>

            <div v-if="isSponsor" class="col-md-12 text-center my-4">
              <input
                type="button"
                @click="addtocart('daylist1')"
                class="btn solid-btn-UNCFGala m-auto mr-2"
                :disabled="!daylist1 || !tickcount1"
                value="Add To Cart"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              />
            </div>

            <hr class="w-100 p-2" />
            <!-- Ticket Options -->

            <!-- Cart details -->
            <div class="col-md-12">
              <div class="text-center">
                <h4
                  class="w-auto d-inline-block"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-weight: 700;
                    font-size: 2.2em;
                    color: #000000;
                  "
                >
                  Cart Details
                </h4>
              </div>
            </div>

            <div class="col-md-12">
              <table style="width: 100%">
                <thead>
                  <tr>
                    <th
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 600;
                        font-size: 1.3em;
                        color: black;
                      "
                    >
                      Selected item(s)
                    </th>
                    <th
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 600;
                        font-size: 1.3em;
                        color: black;
                        text-align: center;
                      "
                    >
                      Quantity
                    </th>
                    <th
                      style="
                        font-family: 'nunito', sans-serif;
                        font-weight: 600;
                        font-size: 1.3em;
                        color: black;
                        text-align: center;
                      "
                    >
                      Amount
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(r, i) in selectedCarts" v-bind:key="r">
                    <td
                      style="
                        width: 60%;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>{{ r.ticketName }}</p>
                    </td>
                    <td
                      style="
                        width: 15%;
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>{{ r.tttcount }}</p>
                    </td>
                    <td
                      style="
                        width: 15%;
                        text-align: center;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.2em;
                        color: black;
                      "
                    >
                      <p>
                        ${{
                          (
                            r.tttcount *
                            (redeemData ? redeemData.price || 0 : r.price)
                          ).toFixed(2)
                        }}
                      </p>
                    </td>
                    <td style="width: 10%; text-align: right; cursor: pointer">
                      <p>
                        <span
                          @click="deleteRec(r, i)"
                          style="
                            color: #d90606;
                            display: block;
                            font-weight: bold;
                            font-family: 'Quicksand';
                          "
                        >
                          X</span
                        >
                      </p>
                    </td>
                  </tr>
                  <tr v-if="selectedCarts.length == 0">
                    <td
                      colSpan="4"
                      style="
                        padding-top: 20px;
                        font-family: 'nunito', sans-serif;
                        font-weight: 500;
                        font-size: 1.1em;
                        color: grey;
                      "
                    >
                      <p class="text-center">
                        No Ticket(s) / Vendor(s) / Sponsorship(s)
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <!-- Cart details -->

            <div
              class="row col-md-12 m-auto mt-4 w-100 p-0 pb-4"
              v-if="
                false && section1.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="ePhoneNumber"
                  class="form-control"
                  placeholder="Phone Number"
                />
              </div>
              <div class="col-md-6">
                <input
                  type="text"
                  v-model="eOrgNumber"
                  class="form-control"
                  placeholder="Organization Or Institution: ex: DEPA (if applicable)"
                />
              </div>
            </div>

            <hr
              class="w-100 p-2"
              v-if="
                TicketsResult[selected] &&
                section2.includes(TicketsResult[selected].ticketCode)
              "
            />
            <div
              class="row"
              v-if="
                TicketsResult[selected] &&
                section2.includes(TicketsResult[selected].ticketCode)
              "
            >
              <div class="col-md-6 mb-2">
                <label class="">Name of Business/Individual</label>
                <input
                  type="text"
                  v-model="nameOfBusiness"
                  class="form-control"
                  placeholder="Name of Business/Individual"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">Address</label>
                <input
                  type="text"
                  v-model="address"
                  class="form-control"
                  placeholder="Address"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">City, State, Zip</label>
                <input
                  type="text"
                  v-model="cityStateZip"
                  class="form-control"
                  placeholder="City, State, Zip"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">Contact Person</label>
                <input
                  type="text"
                  v-model="contactPerson"
                  class="form-control"
                  placeholder="Contact Person"
                />
              </div>

              <div class="col-md-6 mb-2">
                <label class="">Contact Number</label>
                <input
                  type="text"
                  v-model="telephoneNumber"
                  class="form-control"
                  placeholder="Contact Number"
                />
              </div>
              <div class="col-md-6 mb-2">
                <label class="">Email Address</label>
                <input
                  type="text"
                  v-model="emailAddress"
                  class="form-control"
                  placeholder="Email Address"
                />
              </div>
            </div>

            <div
              class="col-md-12 mt-4"
              style="
                font-family: 'nunito', sans-serif;
                font-weight: 500;
                font-size: 1.1em;
                color: grey;
              "
            >
              <input type="checkbox" v-model="reserveFormAccept" />
              I understand that all sales are final. No refunds. I have read and
              agree with the MYLE
              <a
                href="https://myle.com/terms"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms and conditions.</a
              >
            </div>

            <div class="col-md-12 text-center mt-4">
              <button
                type="submit"
                @click="paynowcustom()"
                :disabled="
                  !fullname.trim().length ||
                  !nameOfBusiness.trim().length ||
                  !invalidemail ||
                  !invaliduphone ||
                  isSubmitted ||
                  !selectedCarts.length ||
                  !tickcountlist.length ||
                  !reserveFormAccept
                "
                class="btn solid-btn-UNCFGala m-auto mr-2"
                style="
                  pointer-events: all;
                  cursor: pointer;
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  font-size: 1.2em;
                  font-weight: bold;
                "
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </form>
    </nu-modal>
    <!-- form ends -->

    <div class="container-fluid p-0" style="overflow-x: hidden">
      <!-- Navbar section starts -->
      <div class="row">
        <div class="col-md-12">
          <nav-bar />
        </div>
      </div>
      <!-- Navbar section ends -->
      <!-- banner section starts -->
      <div class="row text-center">
        <div class="col-md-12" id="banner">
          <img
            class="align-middle"
            src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/header_EmpowerEDEntrepreneurs2025_V1.png"
            style="
              width: 100%;
              max-width: 100%;
              height: auto;
              margin-top: 4.6rem;
            "
          />
        </div>
      </div>
      <!-- banner section ends -->
      <div
        class="row w-100 m-0 promo-section MYLE-lite-green"
        style="background-color: #022747; border-top: 1px solid white"
      >
        <div class="col-lg-12 col-md-12">
          <div class="mt-4">
            <div class="section-heading mb-4">
              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 700;
                  color: #ffffff;
                "
              >
                EmpowerED Entrepreneurs: Accelerating Success
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: #ffffff;
                "
              >
                🗓️ Sunday, July 13, 2025 ⏰ 06:00 PM - 08:00 PM

                <br /><br />

                🗓️ Monday, July 14, 2025 ⏰ 08:00 AM - 05:00 PM
              </h1>

              <h1
                class="text-center"
                style="
                  font-family: 'nunito', sans-serif;
                  font-weight: 500;
                  font-size: 18px;
                  color: #ffffff;
                "
              >
                <a
                  href="https://maps.app.goo.gl/GiytG5sxqLQWPzaY9"
                  target="_blank"
                  rel="noopener noreferrer"
                  style="color: #ffffff"
                >
                  📍 Renaissance Hotel <br />
                  7000 Bluebonnet Blvd
                </a>
              </h1>
            </div>
          </div>
        </div>
      </div>
      <!-- Description section starts -->
      <div
        class="row align-items-center justify-content-between test"
        style="margin: auto; margin-top: 20px"
      >
        <div class="col-md-12">
          <div class="col-md-12">
            <div class="download-content mt-2">
              <p
                class=""
                style="
                  font-family: 'nunito', sans-serif;
                  font-size: 1.6em;
                  color: #000000;
                "
              >
                Join us July 13th, at 5pm, for an evening gala where we will
                recognize and celebrate our small and minority-owned businesses.
                Semi-formal attire required.

                <br /><br />
                Meet n' Greet - 5:00pm<br />
                Awards Gala: 6:00pm - 8:00pm
                <br /><br />
                <!-- <br /><br />
                Then meet us back at the Renaissance on Monday, July 14th, at
                8am, to hear from our dynamic speakers as we engage in an
                interactive day of training and networking to empower you and
                help accelerate your business’ success.
                <br /><br />
                Note: 7:30am - Vendor Registration Check-In
                <br /><br />
                You don’t won’t to miss this event as we will be making a very
                special announcement regarding a special opportunity for many of
                our businesses.

                <br /><br /> -->

                General Ticket Price - $25<br />
                Vendor Table Price - $100<br />
                <br />
                Sponsorship Levels:<br />
                Bronze: $250<br />
                Silver: $500<br />
                Gold: $1,500<br />
                Platinum: $5,000<br />
                Partnership: $10,000<br />
              </p>
            </div>

            <!-- <br /> -->

            <!-- <div class="row">
                <div
                  class="col-md-12 col-md-12"
                  style="font-family: 'Montserrat', sans-serif"
                >
                  <p
                    class="text-center"
                    style="
                      font-family: 'nunito', sans-serif;
                      font-size: 1.6em;
                      color: #000000;
                    "
                  >
                    <span
                      style="
                        font-family: 'nunito', sans-serif;
                        font-size: 1.6em;
                        font-weight: 700;
                      "
                    >
                      25 YEARS OF IMPACT
                    </span>
                  </p>
                </div>
                <br />
  
                <div
                  class="row text-center mt-0"
                  style="
                    justify-content: center;
                    align-items: center;
                    display: flex;
                  "
                >
                  <div class="col-md-4 text-center">
                    <img
                      id="i2"
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_strip_UNCFAMindIsGala_1.png"
                      style="border-radius: 3%; width: 100%"
                      class="text-center"
                    />
                  </div>
  
                  <div class="col-md-4 text-center">
                    <img
                      id="i3"
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_strip_UNCFAMindIsGala_2.png"
                      style="border-radius: 3%; width: 100%"
                      class="text-center"
                    />
                  </div>
  
                  <div class="col-md-4 text-center">
                    <img
                      id="i4"
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_strip_UNCFAMindIsGala_3.png"
                      style="border-radius: 1%; width: 100%"
                      class="text-center"
                    />
                  </div>
                </div>
  
                <div
                  class="row text-center mt-3"
                  style="
                    justify-content: center;
                    align-items: center;
                    display: flex;
                  "
                >
                  <div class="col-md-2 text-center"></div>
                  <div class="col-md-4 text-center">
                    <img
                      id="i5"
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_strip_UNCFAMindIsGala_4.png"
                      style="border-radius: 3%; width: 100%"
                      class="text-center"
                    />
                  </div>
  
                  <div class="col-md-4 text-center">
                    <img
                      id="i6"
                      src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_strip_UNCFAMindIsGala_5.png"
                      style="border-radius: 3%; width: 100%"
                      class="text-center"
                    />
                  </div>
                  <div class="col-md-2 text-center"></div>
                </div>
  
                <p
                  class=""
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.6em;
                    color: #000000;
                  "
                >
                  As UNCF Chicago celebrates the 25th anniversary of our annual
                  gala we reflect on the remarkable journey of commitment and
                  impact in the educational landscape of our community. For 25
                  years, this event has not only brought together our dedicated
                  philanthropic community but has also raised $19 million! This
                  milestone is a testament to the generosity and passion of our
                  supporters whose contributions have transformed countless young
                  lives and minds and empowered future generations.
                </p>
              </div>
  
              <hr />
              <br />
  
              <div class="row">
                <div
                  class="col-md-12 col-md-12"
                  style="font-family: 'Montserrat', sans-serif"
                >
                  <p
                    class="text-center"
                    style="
                      font-family: 'nunito', sans-serif;
                      font-size: 1.6em;
                      font-weight: 700;
                      color: #000000;
                    "
                  >
                    <span
                      style="font-family: 'nunito', sans-serif; font-size: 1.6em"
                    >
                      THE CHAIR
                    </span>
                  </p>
                </div>
                <br />
  
                <div
                  class="col-md-12 col-md-12 text-center"
                  style="font-family: 'Montserrat', sans-serif"
                >
                  <img
                    src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_UNCFAMindIsGala_Sherina_Maye_Edwards_Headshot.jpeg"
                    style="width: 40%"
                  />
                  <br />
                  <p
                    class=""
                    style="
                      font-family: 'nunito', sans-serif;
                      font-size: 1.6em;
                      color: #000000;
                    "
                  >
                    <span
                      style="
                        font-family: 'nunito', sans-serif;
                        font-size: 0.9em;
                        font-weight: 700;
                      "
                    >
                      Sherina Maye Edwards, <br />
                      Chair of the 25th Annual UNCF Chicago A Mind Is….Gala
                    </span>
                  </p>
  
                  <div class="section-heading mb-5" style="background: #ffffff">
                    <div class="text-center">
                      <a
                        href="https://whoswhoinblack.com/sherina-maye-edwards-advocate-for-academics-named-chair-of-25th-annual-uncf-gala-in-chicago/"
                        class="btn solid-btn-UNCFGala mb-1 ml-3 mr-3 text-center"
                        style="
                          pointer-events: all;
                          cursor: pointer;
                          font-family: 'nunito', sans-serif;
                          font-weight: 700;
                          font-size: 1em;
                          font-weight: bold;
                        "
                        target="_blank"
                        >Learn More</a
                      >
  
                      <a
                        href="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/bio_UNCFAMindIsGala_Sherina_Maye_Edwards.pdf"
                        class="btn solid-btn-UNCFGala ml-3 mr-3 mb-1 text-center"
                        style="
                          pointer-events: all;
                          cursor: pointer;
                          font-family: 'nunito', sans-serif;
                          font-weight: 700;
                          font-size: 1em;
                          font-weight: bold;
                        "
                        target="_blank"
                        rel="noopener noreferrer"
                        >Check Biography</a
                      >
                    </div>
                  </div>
                </div>
              </div>
  
              <hr />
              <br />
  
              <div class="row">
                <div
                  class="col-md-12 col-md-12"
                  style="font-family: 'Montserrat', sans-serif"
                >
                  <p
                    class="text-center"
                    style="
                      font-family: 'nunito', sans-serif;
                      font-size: 1.6em;
                      font-weight: 700;
                      color: #000000;
                    "
                  >
                    <span
                      style="font-family: 'nunito', sans-serif; font-size: 1.6em"
                    >
                      THE VENUE
                    </span>
                  </p>
                </div>
                <br />
              </div>
  
              <div
                class="col-md-12 col-md-12 text-center"
                style="font-family: 'Montserrat', sans-serif"
              >
                <img
                  src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/THE_GERAGHTY_NIGHT_LOW_V2.jpg"
                  style="width: 70%"
                />
                <br />
                <p
                  class=""
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.6em;
                    color: #000000;
                  "
                >
                  <span
                    style="
                      font-family: 'nunito', sans-serif;
                      font-size: 0.9em;
                      font-weight: 700;
                    "
                  >
                    The Geraghty, 2520 S Hoyne Ave, Chicago, IL 60608
                  </span>
                </p>
              </div>
  
              <hr />
              <br /> -->

            <div class="row text-center">
              <div
                class="col-md-12 col-md-12"
                style="font-family: 'Montserrat', sans-serif"
              >
                <p
                  class="text-center"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.6em;
                    font-weight: 700;
                    color: #000000;
                  "
                >
                  <span
                    style="font-family: 'nunito', sans-serif; font-size: 1.6em"
                  >
                    TICKET OPTIONS
                  </span>
                </p>

                <p
                  class=""
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.6em;
                    color: #000000;
                  "
                >
                  <button
                    class="btn solid-btn-UNCFGala text-center"
                    @click="openmodelmyle(false, false)"
                    style="
                      pointer-events: all;
                      cursor: pointer;
                      margin: auto;
                      display: block;
                      font-family: 'nunito', sans-serif;
                      font-weight: 700;
                      font-size: 1em;
                      font-weight: bold;
                    "
                  >
                    Get Tickets
                  </button>
                </p>
              </div>
              <!-- <br />
  
                <div
                  class="col-md-12 col-md-12 mt-5"
                  style="font-family: 'Montserrat', sans-serif"
                >
                  <div
                    class="section-heading mb-5 text-center"
                    style="background: #ffffff"
                  >
                    <p class="">
                      <a
                        href="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/A_MIND_IS_Gala_Sponsorship_Document.pdf"
                        class="btn solid-btn-UNCFGala mb-1 text-center"
                        style="
                          pointer-events: all;
                          cursor: pointer;
                          font-family: 'nunito', sans-serif;
                          font-weight: 700;
                          font-size: 1em;
                          font-weight: bold;
                        "
                        target="_blank"
                        rel="noopener noreferrer"
                        >Sponsor Packages Detail</a
                      >
                      <br /><br />
                      <span
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 1.2em;
                          color: #000000;
                        "
                      >
                        Contact UNCF Chicago today at
                        <a href="tel:+13128452211"> 312-845-2211</a> or
                        <a href="mailto:UNCFChicago@UNCF.org">
                          UNCFChicago@UNCF.org</a
                        >
                        to explore more meaningful sponsorship opportunities and
                        join us in making a greater impactful in the lives of
                        students!
                      </span>
                    </p>
                  </div>
                </div> -->
            </div>

            <hr />
            <br />

            <div class="row text-center">
              <div
                class="col-md-12 col-md-12"
                style="font-family: 'Montserrat', sans-serif"
              >
                <p
                  class="text-center"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.6em;
                    font-weight: 700;
                    color: #000000;
                  "
                >
                  <span
                    style="font-family: 'nunito', sans-serif; font-size: 1.6em"
                  >
                    VENDOR REGISTRATION
                  </span>
                </p>

                <p
                  class=""
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.6em;
                    color: #000000;
                  "
                >
                  <button
                    class="btn solid-btn-UNCFGala text-center"
                    @click="openmodelmyle(true, false)"
                    style="
                      pointer-events: all;
                      cursor: pointer;
                      margin: auto;
                      display: block;
                      font-family: 'nunito', sans-serif;
                      font-weight: 700;
                      font-size: 1em;
                      font-weight: bold;
                    "
                  >
                    Register
                  </button>
                </p>
              </div>
              <br />
            </div>

            <hr />
            <br />

            <div class="row text-center">
              <div
                class="col-md-12 col-md-12"
                style="font-family: 'Montserrat', sans-serif"
              >
                <p
                  class="text-center"
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.6em;
                    font-weight: 700;
                    color: #000000;
                  "
                >
                  <span
                    style="font-family: 'nunito', sans-serif; font-size: 1.6em"
                  >
                    SPONSORSHIP
                  </span>
                </p>

                <p
                  class=""
                  style="
                    font-family: 'nunito', sans-serif;
                    font-size: 1.6em;
                    color: #000000;
                  "
                >
                  <button
                    class="btn solid-btn-UNCFGala text-center"
                    @click="openmodelmyle(false, true)"
                    style="
                      pointer-events: all;
                      cursor: pointer;
                      margin: auto;
                      display: block;
                      font-family: 'nunito', sans-serif;
                      font-weight: 700;
                      font-size: 1em;
                      font-weight: bold;
                    "
                  >
                    Get Sponsorship
                  </button>
                </p>
              </div>
              <br />
            </div>

            <hr />

            <div
              class="row align-items-center justify-content-between test"
              style="margin: auto; margin-top: 20px"
            >
              <div class="col-md-12">
                <div class="download-content">
                  <p
                    class="text-center mb-3"
                    style="
                      font-family: 'nunito', sans-serif;
                      font-size: 2.3em;
                      font-weight: 700;
                      color: #000000;
                    "
                  >
                    HOTEL ACCOMODATIONS
                  </p>

                  <p
                    class="text-center"
                    style="
                      font-family: 'nunito', sans-serif;
                      font-size: 1.6em;
                      color: #000000;
                    "
                  >
                    Renaissance Baton Rouge Hotel<br />
                    7000 Bluebonnet Boulevard, Baton Rouge, LA 70810
                  </p>
                </div>

                <div class="row justify-content-center text-center">
                  <div
                    class="col-12 col-md-12 justify-content-center d-flex align-items-center"
                  >
                    <div class="">
                      <img
                        src="https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_RenaissanceBatonRouge.jpeg"
                        alt="Slow Jam Social Event"
                        class="w-75 img-fluid"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row w-100">
                <div class="col-lg-12 col-md-12">
                  <div class="section-heading mb-5">
                    <div class="download-btn mt-4 text-center">
                      <p
                        class="text-center mb-1"
                        style="
                          font-family: 'nunito', sans-serif;
                          font-size: 1.2em;
                          color: #000000;
                        "
                      >
                        ( Must book by Jun 13th )
                      </p>

                      <a
                        href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1737393282314&key=GRP&guestreslink2=true&app=resvlink"
                        class="btn solid-btn-UNCFGala"
                        style="
                          pointer-events: all;
                          cursor: pointer;
                          margin: auto;
                          font-family: 'nunito', sans-serif;
                          font-weight: 700;
                          font-size: 1em;
                          font-weight: bold;
                        "
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        BOOK HOTEL</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <hr />
              <br />
  
              <div class="row">
                <div
                  class="col-md-12 col-md-12"
                  style="font-family: 'Montserrat', sans-serif"
                >
                  <p
                    class="text-center"
                    style="
                      font-family: 'nunito', sans-serif;
                      font-size: 1.6em;
                      font-weight: 700;
                      color: #000000;
                    "
                  >
                    <span
                      style="font-family: 'nunito', sans-serif; font-size: 1.6em"
                    >
                      THE EXPERIENCE
                    </span>
                  </p>
                </div>
                <br />
  
                <div class="col-md-12 col-md-12" style="z-index: 90">
                  <vue-flux
                    :options="vfOptions"
                    :images="vfEventImgs"
                    :transitions="vfTransitions"
                    ref="slider"
                    style=""
                  >
                    <template v-slot:preloader>
                      <flux-preloader />
                    </template>
                    <template v-slot:controls>
                      <flux-controls />
                    </template>
                  </vue-flux>
                </div>
              </div>
  
              <br />
              <br /> -->
          </div>
        </div>
      </div>
    </div>
    <site-footer :show-subscribe="true" />
  </div>
</template>

<script>
// Component Imports

import { StripeCheckout } from "@vue-stripe/vue-stripe";
import axios from "axios";
import confirmationPopup from "../../components/confirmationPopup";
import VueElementLoading from "vue-element-loading";
import NavBar from "../../views/commons/NavBar";
import SiteFooter from "../../views/index-five/SiteFooter";
// import { VueFlux, FluxPreloader, FluxControls } from "vue-flux";

// import vueThermometer from "../../../components/VueThermometer.vue";

// defining component state
export default {
  name: "newyear",
  data() {
    return {
      isDonate: false,
      isSponsor: false,
      thermoResult: 0,
      options: {
        text: {
          color: "black",
          fontSize: 14,
          textAdjustmentY: 2,
          fontFamily: "Arial",
          textEnabled: true,
        },
        thermo: {
          color: "#145143",
          backgroundColor: "#ffffff",
          frameColor: "black",
          ticks: 16,
          ticksEnabled: true,
          tickColor: "black",
          tickWidth: "1",
        },
        layout: {
          height: 600,
          width: 140,
        },
      },

      selectedCarts: [],
      ttypelist: null,
      ttypelist1: null,
      nameOfBusiness: "",
      address: "",
      cityStateZip: "",
      contactPerson: "",
      telephoneNumber: "",
      emailAddress: "",
      attendingGala: "Yes",
      participateInVendor: "No",
      names: {},
      showsuccess: false,
      userContactPhone: "",
      vchange: true,
      validSection: true,
      elName: "",
      eEmail: "",
      business_organization_name: "",
      emNumber: "",
      eTitle: "",
      eRefferedBy: "",
      efatherName: "",
      efEmail: "",
      ePhoneNumber: "",
      eOrgNumber: "",
      age: {},
      agecount: {},
      section2: [
        "tk_H9qkqBlDBz8bIkq",
        "tk_bNVWeTS8vqxT5Cq",
        "tk_BwuMrjQpfv88LU4",
        "tk_kfH0omPEs1dh4Cw",
      ],

      donations: [
        "tk_sDYQFWpdeIfHZBA",
        "tk_H9qkqBlDBz8bIkq",
        "tk_bNVWeTS8vqxT5Cq",
        "tk_BwuMrjQpfv88LU4",
        "tk_kfH0omPEs1dh4Cw",
        "tk_J5gD70Jbi4a5Iei",
      ],
      pricemap: {
        tk_Juz3TFft3ukwa9J: "price_1Qp2uWD0vTZ4QB9cXly3wRCQ",
        tk_sDYQFWpdeIfHZBA: "price_1QmWvfD0vTZ4QB9cwjcgPK0a",
        tk_H9qkqBlDBz8bIkq: "price_1QmX1HD0vTZ4QB9cmgTsEUIF",
        tk_bNVWeTS8vqxT5Cq: "price_1QmX1ID0vTZ4QB9c8FEbxK8W",
        tk_BwuMrjQpfv88LU4: "price_1QmX1ID0vTZ4QB9cZn3MGHIt",
        tk_kfH0omPEs1dh4Cw: "price_1QmX1JD0vTZ4QB9cgI80OuoQ",
        tk_J5gD70Jbi4a5Iei: "price_1Qp2uWD0vTZ4QB9cUiFH3JsB",
      },

      daylist: {},
      daylist1: {},
      completeList: [],
      initvalue: {},

      section1: [
        "tk_Juz3TFft3ukwa9J",
        "tk_sDYQFWpdeIfHZBA",
        "tk_H9qkqBlDBz8bIkq",
        "tk_bNVWeTS8vqxT5Cq",
        "tk_BwuMrjQpfv88LU4",
        "tk_kfH0omPEs1dh4Cw",
        "tk_J5gD70Jbi4a5Iei",
      ],

      redeempromo: "",
      redeemPriceId: "",
      redeemData: null,
      reserveFormAccept: false,
      tkList: {
        tk_Juz3TFft3ukwa9J: {
          messages: ["Ticket Price: $25.00"],
        },
        tk_sDYQFWpdeIfHZBA: {
          messages: ["Ticket Price: $100.00"],
        },

        tk_H9qkqBlDBz8bIkq: {
          messages: ["Ticket Price: $250.00"],
        },
        tk_bNVWeTS8vqxT5Cq: {
          messages: ["Ticket Price: $500.00"],
        },
        tk_BwuMrjQpfv88LU4: {
          messages: ["Ticket Price: $1500.00"],
        },
        tk_kfH0omPEs1dh4Cw: {
          messages: ["Ticket Price: $5000.00"],
        },
        tk_J5gD70Jbi4a5Iei: {
          messages: ["Ticket Price: $10000.00"],
        },
      },

      priceIds: {
        tk_Juz3TFft3ukwa9J: "price_1Qp2uWD0vTZ4QB9cXly3wRCQ",
        tk_sDYQFWpdeIfHZBA: "price_1QmWvfD0vTZ4QB9cwjcgPK0a",

        tk_H9qkqBlDBz8bIkq: "price_1QmX1HD0vTZ4QB9cmgTsEUIF",
        tk_bNVWeTS8vqxT5Cq: "price_1QmX1ID0vTZ4QB9c8FEbxK8W",
        tk_BwuMrjQpfv88LU4: "price_1QmX1ID0vTZ4QB9cZn3MGHIt",
        tk_kfH0omPEs1dh4Cw: "price_1QmX1JD0vTZ4QB9cgI80OuoQ",
        tk_J5gD70Jbi4a5Iei: "price_1Qp2uWD0vTZ4QB9cUiFH3JsB",
      },

      countsListShow: [],
      eventTicketResult: [],
      selectedAccor: null,
      ticketForm: {
        name: "",
        email: "",
        phone: "",
        ticketCount: 0,
        ticketName: "",
        ticketId: "",
        method: "",
        accept: "",
        file: null,
      },
      loadStyle: {
        color: "#ffffff",
        "font-weight": "bold",
        "letter-spacing": "1rem",
        "font-size": "18px",
      },
      selected: 0,
      fullname: "",
      email: "",
      userSchool: "",
      tickcount: 1,
      tickcount1: 1,
      phone: "",
      isSubmitted: false,
      tickcountlist: [],
      tickcountlist1: [],

      show: false,
      checkout: {
        clientReferenceId: null,
        publishKey:
          "pk_live_51JEQYTD0vTZ4QB9cnmoJMhJQrCybltMAeG2uN3utIarQ342JyZSyyWFXgEdriazLZRFAR1Crne2E1d9PNQFVGxbw00RTp4QVjn",
      },
      TicketsResult: [],
      vfOptions: {
        autoplay: true,
      },
      vfTransitions: [
        "slide",
        "blinds2d",
        "explode",
        "fade",
        "round2",
        "swipe",
        "warp",
        "zip",
      ],
      vfImages: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/logo.jpeg",
      ],
      vfEventImgs: [
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slider_UNCFAMindIsGala_1.PNG",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slider_UNCFAMindIsGala_2.PNG",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slider_UNCFAMindIsGala_3.PNG",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slider_UNCFAMindIsGala_4.PNG",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slider_UNCFAMindIsGala_5.PNG",
        "https://storage.googleapis.com/wnk1yeeawadm9lfc8qu5ctszt99ghpd1rqh1p7w71bhqiaqfl0tasz1y525o/mylesite/img_slider_UNCFAMindIsGala_6.PNG",
      ],
    };
  },
  components: {
    confirmationPopup,
    VueElementLoading,
    StripeCheckout,
    NavBar,
    SiteFooter,
    // VueFlux,
    // FluxPreloader,
    // FluxControls,
    // vueThermometer,
  },
  methods: {
    nonDonations(list) {
      return list.filter((r) => r[0].t != "daylist1");
    },

    fetchRouteTickets(list, isRoute, isDonation) {
      const routes = ["tk_Juz3TFft3ukwa9J"];
      const donationn = ["tk_sDYQFWpdeIfHZBA"];
      const sponsors = [
        "tk_H9qkqBlDBz8bIkq",
        "tk_bNVWeTS8vqxT5Cq",
        "tk_BwuMrjQpfv88LU4",
        "tk_kfH0omPEs1dh4Cw",
        "tk_J5gD70Jbi4a5Iei",
      ];

      if (isRoute) {
        return list.filter((r) => routes.includes(r.ticketCode));
      }

      if (isDonation) {
        return list.filter((r) => donationn.includes(r.ticketCode));
      }

      return list.filter((r) => sponsors.includes(r.ticketCode));
    },

    fetchStatus() {
      const req = "https://myle-19701.uc.r.appspot.com/api/event_total_sales";
      const self = this;
      self.thermoResult = 0;
      axios
        .post(req, {
          eventId: "qM0RT0yhgjUcDW",
          userId: "",
        })
        .then((res) => {
          if (res && res.data && res.data.Result) {
            self.thermoResult =
              res.data.Result.totalSales > 0
                ? res.data.Result.totalSales / 1000
                : 0;
          }
        });
    },

    // addtocart(rec) {
    //   if (rec) {
    //     rec.tttcount = this.tickcount;
    //     this.selectedCarts.push({ ...rec });
    //   }
    //   this.tickcount = 1;
    //   this.ttypelist = this.TicketsResult[0];
    //   this.selected = 0;
    // },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    addtocart(t) {
      const rec = { ...this[t] };
      if (t == "daylist" && this.tickcount < 1) {
        return;
      }
      if (t == "daylist1" && this.tickcount1 < 1) {
        return;
      }
      if (rec && t == "daylist") {
        for (let c = 0; c < this.tickcount; c++) {
          rec.tttcount = 1;
          rec.daylist = this[t];
          this.selectedCarts.push({ ...rec, t: t });
        }

        this.setValues(
          (this.cntholder[rec.daylist.ticketCode] || 0) + this.tickcount,
          t
        );
        console.log("dsds", this.cntholder);
      }

      if (rec && t == "daylist1") {
        for (let c = 0; c < this.tickcount1; c++) {
          rec.tttcount = 1;
          rec.daylist = this[t];
          this.selectedCarts.push({ ...rec, t: t });
        }

        this.setValues(
          (this.cntholder[rec.daylist.ticketCode] || 0) + this.tickcount1,
          t
        );
        console.log("dsds", this.cntholder);
      }

      this.dayChange(t);
    },

    dayChange(t) {
      if (t == "daylist") {
        this.tickcount = 1;
        this.tickcountlist = [];
      } else {
        this.tickcount1 = 1;
        this.tickcountlist1 = [];
      }
      const w = this[t];
      for (
        let k = 0;
        k <
        (w.availableTickets > w.allowedPerRequest
          ? w.allowedPerRequest
          : w.availableTickets);
        k++
      ) {
        if (!this.donations.includes(w.ticketCode)) {
          this.tickcountlist.push(k + 1);
        } else if (this.donations.includes(w.ticketCode)) {
          this.tickcountlist1.push(k + 1);
        }
      }
      if (t === "daylist") {
        if (this.cntholder && this.cntholder[this[t].ticketCode] > 0) {
          const len = this.tickcountlist.length;
          this.tickcountlist = [];
          for (
            let k1 = 0;
            k1 < len - this.cntholder[this[t].ticketCode];
            k1++
          ) {
            this.tickcountlist.push(k1 + 1);
          }
        }

        if (this.tickcountlist.length == 0) {
          this.tickcount = 0;
        }
      } else {
        if (this.cntholder && this.cntholder[this[t].ticketCode] > 0) {
          const len = this.tickcountlist1.length;
          this.tickcountlist1 = [];
          for (
            let k1 = 0;
            k1 < len - this.cntholder[this[t].ticketCode];
            k1++
          ) {
            this.tickcountlist1.push(k1 + 1);
          }
        }

        if (this.tickcountlist1.length == 0) {
          this.tickcount1 = 0;
        }
      }

      if (t == "daylist") {
        this.ttypelist = { ...this[t] };
        if (!(this[t].ticketCode in this.initvalue)) {
          this.initvalue[this[t].ticketCode] = this.tickcountlist.length;
        }
      } else {
        this.ttypelist1 = { ...this[t] };
        if (!(this[t].ticketCode in this.initvalue)) {
          this.initvalue[this[t].ticketCode] = this.tickcountlist1.length;
        }
      }
    },

    deleteRec(rec, i) {
      console.log(i);
      this.selectedCarts = this.selectedCarts.filter((x, it) => it != i);
      this.ttypelist = this.TicketsResult[0];
    },
    isvalidName() {
      let isValid = true;
      if (this.tickcount < 1) {
        return isValid;
      }
      Object.keys(this.names).forEach((e) => {
        if (!e.includes("email") && this.names[e].trim().length < 1) {
          isValid = false;
        } else if (e.includes("email")) {
          const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
          if (!re.test(this.names[e].trim())) {
            isValid = false;
          }
        }
      });

      return (this.vchange || !this.vchange) && isValid;
    },
    totalCount(list) {
      if (this.TicketsResult[this.selected]) {
        const tmp = Object.keys(list).length;
        return tmp > 1 ? [...Array(tmp - 1).keys()] : [];
      }

      return [];
    },
    ticketChange() {
      let code = this.ttypelist.ticketCode;
      this.selected = 0;
      this.ttypelist.tttcount = 1;
      let idx = 0;
      this.TicketsResult.forEach((t, ti) => {
        if (t.ticketCode === code) {
          idx = ti;
        }
      });
      this.selected = idx;
      this.tickcountlist = [];
      this.tickcount = 1;
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    countChange() {
      const tmp = { ...this.names };
      this.names = {};

      if (this.tickcount > 1) {
        for (let i = 0; i < this.tickcount - 1; i++) {
          this.names["name" + i] = tmp["name" + i] || "";
          this.names["email" + i] = tmp["email" + i] || "";
        }
      }
    },
    invalidNum(n) {
      return (n || "").match(/\d/g) && (n || "").trim().length >= 10;
    },
    checkInvalidEmail(e) {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(e);
    },
    section1Filter(list) {
      return list.filter((l) => this.section1.includes(l.ticketCode));
    },
    section2Filter(list) {
      return list.filter((l) => this.section2.includes(l.ticketCode));
    },
    filteredMessages(obj) {
      if (obj && this.tkList && this.tkList[obj.ticketCode]) {
        return this.tkList[obj.ticketCode].messages;
      }

      return [];
    },
    closeModal() {
      this.showsuccess = false;
      this.$refs.confirmmodal.close();
    },
    onChangeTicket: function (evt) {
      const countsList = [];

      (this.eventTicketResult || []).forEach((selectedTicket, idx) => {
        if (selectedTicket.ticketName === evt.target.value) {
          this.ticketForm.ticketId = selectedTicket.ticketCode;
          const at = this.eventTicketResult[idx].availableTickets;
          const apr = this.eventTicketResult[idx].allowedPerRequest;

          for (let k = 0; k < (at > apr ? apr : at); k++) {
            countsList.push(k + 1);
          }
        }
      });

      this.countsListShow = countsList;
    },
    validateFileType: function (ee) {
      const fileName = document.getElementById("fileName").value;
      const idxDot = fileName.lastIndexOf(".") + 1;
      const extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
        this.ticketForm.file = ee.target.files[0];
      }
    },
    openmodelmyle(isDonate, isSponsor) {
      this.isDonate = isDonate;
      this.isSponsor = isSponsor;
      this.validSection = true;
      this.nameOfBusiness = "";
      this.attendingGala = "Yes";
      this.participateInVendor = "No";
      this.address = "";
      this.cityStateZip = "";
      this.contactPerson = "";
      this.telephoneNumber = "";
      this.emailAddress = "";
      this.fullname = "";
      this.email = "";
      this.userSchool = "";
      this.elName = "";
      this.eEmail = "";
      this.business_organization_name = "";
      this.emNumber = "";
      this.eTitle = "";
      this.eRefferedBy = "";
      this.efatherName = "";
      this.efEmail = "";
      this.userContactPhone = "";
      this.ePhoneNumber = "";
      this.eOrgNumber = "";
      this.age = {};
      this.reserveFormAccept = false;
      this.selectedCarts = [];
      this.names = {};
      this.ttypelist = this.TicketsResult[0];
      this.ttypelist = null;
      this.ttypelist1 = null;
      this.daylist = {};
      this.daylist1 = {};
      this.tickcount = 1;
      this.tickcount1 = 1;
      this.selectedCarts = [];
      this.tickcountlist = [];
      this.tickcountlist1 = [];
      this.initvalue = {};
      this.dayChange("daylist");
      this.dayChange("daylist1");
      this.redeemData = null;
      this.redeemPriceId = "";
      this.$refs.modal.open();
      this.selected = 0;
      const idx = 0;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    openmodel: function (code) {
      this.validSection = true;
      this.nameOfBusiness = "";
      this.attendingGala = "Yes";
      this.participateInVendor = "No";
      this.address = "";
      this.cityStateZip = "";
      this.contactPerson = "";
      this.telephoneNumber = "";
      this.emailAddress = "";
      this.fullname = "";
      this.email = "";
      this.userSchool = "";
      this.elName = "";
      this.eEmail = "";
      this.business_organization_name = "";
      this.emNumber = "";
      this.eTitle = "";
      this.eRefferedBy = "";
      this.efatherName = "";
      this.efEmail = "";
      this.ePhoneNumber = "";
      this.eOrgNumber = "";
      this.age = {};
      this.reserveFormAccept = false;
      this.selectedCarts = [];
      this.names = {};
      this.ttypelist = this.TicketsResult[0];
      if (code == "RZ44zOQ66InQgZ") {
        this.age["age1"] = "";
      } else if (code == "tk_eVzbCBqzSuFPfd5") {
        this.age["age1"] = "";
        this.age["age2"] = "";
      } else if (code == "tk_g19EFw7kOMyfuL8") {
        this.age["age1"] = "";
        this.age["age2"] = "";
        this.age["age3"] = "";
      } else if (code == "tk_rFyeu7irKUhlI1U") {
        this.age["age1"] = "";
        this.age["age2"] = "";
        this.age["age3"] = "";
        this.age["age4"] = "";
      }
      let idx = 0;
      this.TicketsResult.forEach((t, ti) => {
        if (t.ticketCode === code) {
          idx = ti;
        }
      });
      this.redeemData = null;
      this.redeemPriceId = "";

      this.$refs.modal.open();
      this.selected = idx;
      this.tickcountlist = [];
      for (
        let k = 0;
        k <
        (this.TicketsResult[idx].availableTickets >
        this.TicketsResult[idx].allowedPerRequest
          ? this.TicketsResult[idx].allowedPerRequest
          : this.TicketsResult[idx].availableTickets);
        k++
      ) {
        this.tickcountlist.push(k + 1);
      }
    },
    paynowcustom: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      const payloadData = {
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        userId: "",
        eventId: this.TicketsResult[0].eventId,
        origin: "WEB",
        tickets: [],
      };

      const payloaddata = {
        priceId: this.redeemPriceId,
        eventId: this.TicketsResult[0].eventId,
        ticketId: this.selectedCarts[0].ticketCode,
        totalTickets: this.selectedCarts.length,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
        additionalInfo: {},
        extraInfo: {
          phone: this.userContactPhone,
          business: this.nameOfBusiness,
          attendingGala: this.attendingGala,
          participateInVendor: this.participateInVendor,
        },
      };

      this.selectedCarts.forEach((re) => {
        payloadData.tickets.push({
          priceId: this.redeemPriceId || this.priceIds[re.ticketCode],
          ticketId: re.ticketCode,
          totalTickets: re.tttcount,
          additionalInfo: {},
          extraInfo: {
            phone: this.userContactPhone,
            business: this.nameOfBusiness,
            attendingGala: this.attendingGala,
            participateInVendor: this.participateInVendor,
          },
        });
      });

      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "request_cart_tickets_v3"
              : "requested_free_tickets"),
          (this.TicketsResult[vm.selected].ticketType != "FREE" &&
            !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
            ? payloadData
            : payloaddata
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (re.data && re.data.Result) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.isSubmitted = false;
            vm.$refs.modal.close();
          } else {
            vm.$toast.error("Session Id not Received", { timeout: 4000 });
          }
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          /*  vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
                  vm.$refs.modal.close();
                  vm.reserveFormAccept = false;
                  vm.fullname = "";
                  vm.email = "";
                  vm.tickcount = 1;
                  vm.fetchAllTickets();

                vm.isSubmitted = false;
                vm.reserveFormAccept = false;
                vm.redeemData = null;
                vm.redeemPriceId = "";

                this.showsuccess = false; */
        });
    },
    paynow: function () {
      /**
       * This function here is for  pay methods
       */
      this.isSubmitted = true;
      const vm = this;
      let namelist = {};
      let elist = {};
      let i = 1;
      Object.keys(this.names).forEach((e) => {
        if (e.includes("email")) {
          elist["additional_guest_email_" + i] = this.names[e];
          i += 1;
        }
      });

      i = 1;
      Object.keys(this.names).forEach((e) => {
        if (e.includes("name")) {
          namelist["additional_guest_name_" + i] = this.names[e];
          i += 1;
        }
      });
      const payloadToPush = {
        priceId:
          this.TicketsResult[vm.selected].discount &&
          this.redeemPriceId.trim().length
            ? this.redeemPriceId
            : this.priceIds[this.TicketsResult[vm.selected].ticketCode],
        eventId: this.TicketsResult[vm.selected].eventId,
        ticketId: this.TicketsResult[vm.selected].ticketCode,
        ticketCode: this.TicketsResult[vm.selected].ticketCode,
        totalTickets: this.tickcount,
        userName: this.fullname,
        userEmail: this.email.toLowerCase(),
      };

      if (
        !payloadToPush.extraInfo &&
        this.section2.includes(this.TicketsResult[this.selected].ticketCode)
      ) {
        payloadToPush.extraInfo = {};
        payloadToPush.extraInfo["name_of_business"] = this.nameOfBusiness;
        payloadToPush.extraInfo["address"] = this.address;
        payloadToPush.extraInfo["city_state_zip"] = this.cityStateZip;
        payloadToPush.extraInfo["contact_person"] = this.contactPerson;
        payloadToPush.extraInfo["contact_number"] = this.telephoneNumber;
        payloadToPush.extraInfo["email_address"] = this.emailAddress;
      }
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/" +
            ((this.TicketsResult[vm.selected].ticketType != "FREE" &&
              !this.redeemData) ||
            (this.redeemData && this.redeemData.price !== 0)
              ? "requested_tickets"
              : "requested_free_tickets"),
          payloadToPush
        )
        .then((re) => {
          if (re.data && re.data.Result && re.data.Result.sessionId) {
            vm.checkout.clientReferenceId = re.data.Result.sessionId;
            localStorage.setItem("refsId", vm.checkout.clientReferenceId);
            vm.$refs.checkoutRef.redirectToCheckout();
            setTimeout(() => {
              vm.isSubmitted = false;
            }, 60000);
            vm.reserveFormAccept = false;
          } else if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.userSchool = "";
            vm.tickcount = 1;
            vm.isSubmitted = false;
            vm.fetchAllTickets();
            vm.reserveFormAccept = false;
          }

          vm.redeemData = null;
          vm.redeemPriceId = "";

          setTimeout(() => {
            vm.showsuccess = true;
            vm.$refs.confirmmodal.open();
          }, 1000);
        })
        .catch(() => {
          if (
            vm.TicketsResult[vm.selected].ticketType === "FREE" ||
            (this.redeemData && this.redeemData.price === 0)
          ) {
            vm.$toast.error("Ticket purchase failed", { timeout: 4000 });
            vm.$refs.modal.close();
            vm.reserveFormAccept = false;
            vm.fullname = "";
            vm.email = "";
            vm.userSchool = "";
            vm.tickcount = 1;
            vm.fetchAllTickets();
          }
          vm.isSubmitted = false;
          vm.reserveFormAccept = false;
          vm.redeemData = null;
          vm.redeemPriceId = "";

          this.showsuccess = false;
          setTimeout(() => {
            this.$refs.confirmmodal.open();
          }, 1000);
        });
    },
    redeem: function () {
      const vm = this;
      vm.isSubmitted = true;
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/redeem_promo_code",
        data: {
          eventId: this.TicketsResult[this.selected].eventId,
          ticketId: this.TicketsResult[this.selected].ticketCode,
          promoCode: this.redeempromo,
        },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
            vm.redeemPriceId = re.data.Result.discountedPriceId;
            vm.redeemData = re.data.Result;
            console.log("innnnnnnnnnnn");
          }
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.$toast.error("Promo code failed", { timeout: 4000 });
          vm.redeemPriceId = "";
          vm.redeemData = null;
        });
    },
    fetchAllTickets: function () {
      /**
       * This function here is used to fetch tickets
       */

      const vm = this;
      this.isSubmitted = true;
      this.eventTicketResult = [];
      this.TicketsResult = [];
      const payloadToPush = {
        eventId: "qM0RT0yhgjUcDW",
      };
      axios
        .post(
          "https://myle-19701.uc.r.appspot.com/api/fetchSponsoredEventTicketsWithSeating",
          payloadToPush
        )
        .then((ticketsResult) => {
          if (
            ticketsResult &&
            ticketsResult.data &&
            ticketsResult.data.Result
          ) {
            vm.TicketsResult = ticketsResult.data.Result;
          }

          vm.TicketsResult = vm.TicketsResult.filter(
            (rec) => vm.tkList[rec.ticketCode]
          );

          if (ticketsResult && ticketsResult.data.Result) {
            vm.priceedTicket = (ticketsResult.data.Result.filter(
              (r) => r.ticketCode === "tk_abUzszRQvcC6hQF"
            ) || [])[0];
            console.log(vm.priceedTicket);
            vm.TicketsResult = ticketsResult.data.Result.filter(
              (r) => this.pricemap[r.ticketCode]
            );
            vm.completeList = ticketsResult.data.Result.filter(
              (r) => this.pricemap[r.ticketCode]
            );
          }
          vm.TicketsResult = vm.completeList;
          vm.eventTicketResult = vm.TicketsResult;
          vm.dayChange("daylist");
          vm.dayChange("daylist1");
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
        });
    },
    payfornow: function () {
      const vm = this;
      vm.isSubmitted = true;

      // creating form Data
      const bodyFormData = new FormData();
      bodyFormData.append("fullName", this.ticketForm.name);
      bodyFormData.append("email", this.ticketForm.email.toLowerCase());
      bodyFormData.append("phoneNumber", this.ticketForm.phone);
      bodyFormData.append("ticketId", this.ticketForm.ticketId);
      bodyFormData.append("quantity", this.ticketForm.ticketCount);
      bodyFormData.append("paymentType", this.ticketForm.method);
      bodyFormData.append("paymentReceipt", this.ticketForm.file);
      bodyFormData.append(
        "additionalInfo",
        JSON.stringify({
          type: "Note",
          value: "All sales are final - no refunds",
        })
      );

      // making api call
      axios({
        method: "post",
        url: "https://myle-19701.uc.r.appspot.com/api/ticket_order_confirmation",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((re) => {
          if (re.data.Status === "Success") {
            vm.$toast.success(re.data.Message, { timeout: 4000 });
          }
          // make get request again
          this.fetchAllTickets();
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.isSubmitted = false;
        })
        .catch(() => {
          vm.isSubmitted = false;
          vm.ticketForm = {
            name: "",
            email: "",
            phone: "",
            ticketCount: 0,
            ticketName: "",
            ticketId: "",
            method: "",
            accept: "",
            file: null,
          };
          document.getElementById("fileName").value = "";
          vm.$toast.error("Ticket Confirmation failed", { timeout: 4000 });
        });
    },
  },
  mounted() {
    console.log("================abc");
    this.fetchStatus();
    this.fetchAllTickets();
    const id = 1; // localStorage.getItem("refsId");
    if (
      id &&
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "success"
    ) {
      this.$refs.confirmmodal.open();
      localStorage.removeItem("refsId");
    } else if (
      this.$route &&
      this.$route.query &&
      this.$route.query.checkout == "failed"
    ) {
      this.$refs.confirmmodal.open();
      localStorage.removeItem("refsId");
    }
  },
  computed: {
    invaliduphone() {
      if (this.userContactPhone.length <= 0) {
        return true;
      }
      return (
        (this.userContactPhone || "").match(/\d/g) &&
        (this.userContactPhone || "").trim().length >= 10
      );
    },
    completed() {
      console.log(this.showcreateForm);
      let isFilled = true;
      Object.values(this.sections).forEach((rec) => {
        rec.forEach((item) => {
          if (!item.data && item.t != "daylist1") {
            isFilled = false;
          }
        });
      });

      if (!Object.keys(this.sections).length) {
        return false;
      }

      return isFilled;
    },

    validEmail() {
      const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return re.test(this.emailAddress);
    },
    tellPno() {
      return (
        (this.telephoneNumber || "").match(/\d/g) &&
        (this.telephoneNumber || "").trim().length >= 10
      );
    },
    invalidTicketEmail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(
        this.ticketForm.email
      );
    },
    invalidTicketPhone() {
      return (
        (this.ticketForm.phone || "").match(/\d/g) &&
        (this.ticketForm.phone || "").trim().length >= 10
      );
    },
    invalidPno() {
      return (
        (this.phoneNo || "").match(/\d/g) &&
        (this.phoneNo || "").trim().length >= 10
      );
    },
    invalidemail() {
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(this.email);
    },
    invalidphone() {
      return (
        (this.phone || "").match(/\d/g) &&
        (this.phone || "").trim().length >= 10
      );
    },
  },
  watch: {
    selectedCarts(list) {
      const w = {};
      this.sections = JSON.parse(JSON.stringify(this.sections));

      list.forEach((r) => {
        if (!w[r.ticketCode]) {
          w[r.ticketCode] = [];
        }
        for (let e = 0; e < r.tttcount; e++) {
          w[r.ticketCode].push({ ...r });
        }
      });

      if (this.sections.length) {
        Object.keys(w).forEach((h) => {
          let present = false;

          this.sections.forEach((rr) => {
            if (rr[0].ticketCode === h) {
              present = true;
            }
          });

          if (!present) {
            this.sections.push(JSON.parse(JSON.stringify(w[h])));
          }
        });

        this.sections.forEach((rr, i) => {
          Object.keys(w[rr[0].ticketCode]).forEach((wx) => {
            if (!this.sections[i][wx]) {
              this.sections[i].push(w[rr[0].ticketCode][wx]);
            }
          });
        });

        this.sections = [...this.sections];
      } else {
        this.sections = Object.values(w);
      }
    },
  },
  metaInfo: {
    title: "MYLE - EmpowerED Entrepreneurs: Accelerating Success",
    meta: [
      {
        name: "description",
        content: "MYLE - EmpowerED Entrepreneurs: Accelerating Success",
      },

      {
        name: "keywords",
        content: "MYLE - EmpowerED Entrepreneurs: Accelerating Success",
      },

      {
        name: "copyright",
        content: "MYLE",
      },

      {
        name: "url",
        content:
          "https://myle.com/empowered-entrepreneurs-accelerating-success",
      },
    ],
  },
};
</script>

<style scoped>
#reserve {
  color: white;
}
#reserve:hover {
  color: #104233;
}
.background-overlay {
  background: #114436c4 !important;
  max-width: 100%;
  z-index: 9999 !important;
}
#banner {
  background-size: cover;
}
.innerimg {
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
  max-width: 80%;
  height: auto;
  border: 1px double #d7c57d;
}

.bg-disable {
  background: pink;
  border: 1px solid pink;
  color: red !important;
  pointer-events: none;
}
.bolded {
  font-weight: bold;
}

.nu-modal.has-content.is-visible {
  max-width: 600px !important;
}
</style>
